import { workDirectionKindNameMap } from 'constants/define';
import React from 'react';
import { useWorkNoticeEditStore } from 'store/topSales/order/workNoticeEdit/hooks';
import { useWorkNoticeEditCheckStore } from 'store/topSales/order/workNoticeEditCheck/hooks';

import { Box, useTheme } from '@mui/material';

import { AttentionLabel, Typography } from 'components/parts/atoms';

import { usePageContext } from '../hooks/usePageContext';

/** 作業予定破棄内容コンポーネント */
const DiscardSchemeAlert: React.VFC = () => {
  const theme = useTheme();
  const {
    state: { data: workData },
  } = useWorkNoticeEditStore();
  const workForm = workData?.form ?? null;

  const {
    state: { data: workCheckData },
  } = useWorkNoticeEditCheckStore();
  const workCheckForm = workCheckData?.form ?? null;

  const { orderMetaData } = usePageContext();

  const discardReserved = !workCheckForm || workCheckForm.discardScheme;

  if (!workForm || !orderMetaData) return <></>;

  if (workData?.jobData.jobId !== orderMetaData.jobId) return <></>;

  const kindName: string = workDirectionKindNameMap[workForm.kind];
  let discardContent = '';
  if (discardReserved) {
    if (workForm.discardImageScheme && workForm.discardMakingScheme) {
      discardContent = '（画変・製変）';
    } else if (workForm.discardImageScheme) {
      discardContent = '（画変）';
    } else if (workForm.discardMakingScheme) {
      discardContent = '（製変）';
    }
  }

  return (
    <>
      <Box sx={{ backgroundColor: theme.palette.cyclonistGrey.main, p: 1, my: 2 }}>
        {workForm.discardImageScheme && discardReserved && (
          <AttentionLabel variant='h3'>保存時に画像作業記録が破棄されます</AttentionLabel>
        )}
        {workForm.discardMakingScheme && discardReserved && (
          <AttentionLabel variant='h3'>保存時に製版作業記録が破棄されます</AttentionLabel>
        )}

        <Typography>{`指示内容：${kindName}${discardContent}`}</Typography>
        <Typography>{`指示事項：${workForm.noteGenerally}`}</Typography>
      </Box>
    </>
  );
};

export default DiscardSchemeAlert;
