import { useScroll } from 'hooks/useScroll';
import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, createTheme, styled, Tab, Theme, ThemeProvider } from '@mui/material';

import { OnlyTypeKey } from 'utils/typeUtils';

import { FormChildrenContext } from '../contexts/FormChildrenContext';
import { useFlexoOrderEditMaster } from '../hooks/useFlexoOrderEditMaster';
import { usePageContext } from '../hooks/usePageContext';
import { FlexoOrderEditForm as FlexoOrderEditFormType, Address, OrderEditTabIndex } from '../types';
import Basis from './Basis';
import DiscardSchemeAlert from './DiscardSchemeAlert';
import Header from './Header';
import Making from './Making';
import Price from './Price';
import { PriceWatcher } from './PriceWatcher';
import Schedule from './Schedule';

const FooterTabList = styled(TabList)({
  minHeight: 0,
});

const FooterTab = styled(Tab)(({ theme }) => ({
  minWidth: 0,
  minHeight: 0,
  paddingRight: theme.spacing(5.5),
  paddingLeft: theme.spacing(5.5),
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  fontSize: theme.typography.pxToRem(14),
}));

/** 受注入力コンポーネント */
const FlexoOrderEditForm: React.VFC<{
  tabSwitchInterceptor?: (from: OrderEditTabIndex, to: OrderEditTabIndex) => Promise<boolean>;
  dialogFlg?: boolean;
}> = ({ tabSwitchInterceptor, dialogFlg }) => {
  const { tabIndex, setTabIndex, hasFormSet } = usePageContext();
  const { getValues } = useFormContext<FlexoOrderEditFormType>();
  const { master } = useFlexoOrderEditMaster();
  const { scrollTop } = useScroll();

  // 引取先の詳細表示フラグ
  const [openDelivery, setOpenDelivery] = useState(false);

  /**
   * タブ変更イベント
   * @param event イベントオブジェクト
   * @param newValue 現在の選択値
   */
  const onTabChange = (
    event: React.SyntheticEvent,
    newValue: OrderEditTabIndex,
    withScroll = false,
  ) => {
    if (!tabSwitchInterceptor) {
      setTabIndex(newValue);
      return;
    }

    (async () => {
      const passed = await tabSwitchInterceptor(tabIndex, newValue);
      if (passed) {
        setTabIndex(newValue);
        if (withScroll) {
          scrollTop('auto');
        }
      }
    })();
  };

  /** 住所の詳細が入力されているか判定 */
  const isAddressInputted = (
    name: OnlyTypeKey<FlexoOrderEditFormType['basis'], Address>,
  ): boolean => {
    const [address] = getValues([`basis.${name}`]);
    const isInputted = (value: string): boolean => {
      return value.trim().length > 0;
    };
    if (
      isInputted(address.name) ||
      isInputted(address.zipCd) ||
      isInputted(address.prefectureId) ||
      isInputted(address.address1) ||
      isInputted(address.address2) ||
      isInputted(address.builName) ||
      isInputted(address.tel)
    ) {
      return true;
    }
    return false;
  };

  // フォームデータセット時の処理
  useEffect(() => {
    if (hasFormSet) {
      // 引取先を展開して表示するかをセット
      setOpenDelivery(master.deliveryPlaceList.length === 0 || isAddressInputted('deliveryPlace'));
    }
  }, [hasFormSet]);

  // Render
  return (
    <>
      <ThemeProvider
        theme={(theme: Theme) =>
          createTheme({
            ...theme,
            palette: {
              ...theme.palette,
              cyclonistHeadGridColor: {
                main: theme.palette.flexoJob.main,
                contrastText: theme.palette.flexoJob.contrastText,
              },
            },
          })
        }
      >
        <FormChildrenContext.Provider
          value={{
            openDeliveryState: [openDelivery, setOpenDelivery],
          }}
        >
          {/* 価格情報フォーム監視 */}
          <PriceWatcher />

          <Box sx={{ paddingX: 2 }}>
            <Header />
          </Box>
          <Box my={4} />

          {/* WORKで作業予定破棄が指定されている場合は破棄内容を表示 */}
          {!dialogFlg && <DiscardSchemeAlert />}

          <TabContext value={tabIndex}>
            <Box sx={{ borderBottom: 1 }}>
              <TabList
                onChange={(event, newValue: OrderEditTabIndex) => {
                  onTabChange(event, newValue);
                }}
              >
                <Tab label='基本情報' value={OrderEditTabIndex.Basis}></Tab>
                <Tab label='作業予定' value={OrderEditTabIndex.Schedule}></Tab>
                <Tab label='製版情報' value={OrderEditTabIndex.Making}></Tab>
                <Tab label='価格情報' value={OrderEditTabIndex.Price}></Tab>
              </TabList>
            </Box>
            <TabPanel value={OrderEditTabIndex.Basis}>
              <Basis />
            </TabPanel>
            <TabPanel value={OrderEditTabIndex.Schedule}>
              <Schedule />
            </TabPanel>
            <TabPanel value={OrderEditTabIndex.Making}>
              <Making />
            </TabPanel>
            <TabPanel value={OrderEditTabIndex.Price}>
              <Price />
            </TabPanel>
          </TabContext>

          <TabContext value={tabIndex}>
            <Box>
              <FooterTabList
                onChange={(event, newValue: OrderEditTabIndex) => {
                  onTabChange(event, newValue, true);
                }}
              >
                <FooterTab label='基本情報' value={OrderEditTabIndex.Basis} />
                <FooterTab label='作業予定' value={OrderEditTabIndex.Schedule} />
                <FooterTab label='製版情報' value={OrderEditTabIndex.Making} />
                <FooterTab label='価格情報' value={OrderEditTabIndex.Price} />
              </FooterTabList>
            </Box>
          </TabContext>
        </FormChildrenContext.Provider>
      </ThemeProvider>
    </>
  );
};

export default FlexoOrderEditForm;
