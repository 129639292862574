import useMessage from 'hooks/useMessage';
import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { Box, Container } from '@mui/material';

import usePriceApprovalEdit from 'pages/priceApproval/PriceApprovalList/hooks/usePriceApprovalEdit';
import OtherOrderEdit from 'pages/topSales/order/OtherOrder/OtherOrderEdit/components/OtherOrderEditForm';
import { OtherOrderEditMasterContext } from 'pages/topSales/order/OtherOrder/OtherOrderEdit/contexts/OtherOrderEditMasterContext';
import { PageContext } from 'pages/topSales/order/OtherOrder/OtherOrderEdit/contexts/PageContext';
import { initialOtherOrderEdit } from 'pages/topSales/order/OtherOrder/OtherOrderEdit/defaults';
import {
  OtherOrderEditForm,
  OrderEditTabIndex,
  PageState,
} from 'pages/topSales/order/OtherOrder/OtherOrderEdit/types';
import { useOtherOrderEdit } from 'pages/topSales/order/OtherOrder/OtherOrderEditDialog/hooks/useOtherOrderEdit';

import MessageListener from 'components/Message/MessageListener';
import { Button, Circular, CloseButton, Heading, Typography } from 'components/parts/atoms';
import { NewWindow } from 'components/parts/atoms/NewWindow';
import { Dialog, FooterButtonsContainer } from 'components/parts/molecules';

type Props = {
  open: boolean;
  showStatus: string;
  onClose: () => void;
  jobId: number | null;
  isWindow?: boolean;
  onUpdated: (jobId: number | null) => void;
};

/** その他受注詳細ダイアログ */
const OtherOrderEditDialog: React.VFC<Props> = (props) => {
  const messageApi = useMessage();
  const history = useHistory();

  /** RHFオブジェクト */
  const formMethod = useForm<OtherOrderEditForm>({
    defaultValues: initialOtherOrderEdit,
  });

  // フォームデータセット済みフラグ
  const [hasFormSet, setHasFormSet] = useState(false);

  /** その他受注データ */
  const orderData = useOtherOrderEdit(props.jobId, messageApi);

  // isOpenWindowがtrueの場合は別Windowで表示する(左記以外はDialog)
  const [isOpenWindow, setOpenWindow] = useState(false);

  // フォームデータをセット
  useEffect(() => {
    if (orderData?.form) {
      formMethod.reset(orderData.form);
      setHasFormSet(true);
    } else {
      formMethod.reset(initialOtherOrderEdit);
      setHasFormSet(false);
    }
  }, [orderData?.form]);

  useEffect(() => {
    setOpenWindow(props.isWindow ?? false);
  }, [props.isWindow]);

  const [tabIndex, setTabIndex] = useState<OrderEditTabIndex>(OrderEditTabIndex.Price);

  /** 承認フラグ更新アクション */
  const { updatePriceApproval } = usePriceApprovalEdit(
    props.jobId,
    orderData?.form.price.approvalFlg,
    orderData?.form.price.secondApprovalFlg,
    props.showStatus,
    messageApi,
  );

  // #region イベント
  /**
   * 承認ボタン押下時
   * 承認フラグ更新用アクション
   */
  const approvalAction = async () => {
    await updatePriceApproval();
    await props.onUpdated(props.jobId);
    props.onClose();
  };

  const onClose = () => {
    formMethod.reset(initialOtherOrderEdit);
    setHasFormSet(false);
    props.onClose();
  };

  /** 別画面に遷移する（参照JOBNoクリック時など） */
  const leavePage = (location: string) => {
    history.push(location);
  };
  // #endregion

  if (!orderData) {
    return <Circular />;
  }

  // ダイアログ(Window)のBoby部
  const otherBody = (): JSX.Element => {
    return (
      <PageContext.Provider
        value={{
          pageState: PageState.Check,
          tabIndex,
          setTabIndex,
          hasFormSet,
          leavePage,
          orderMetaData: orderData.oderMetaData,
        }}
      >
        <OtherOrderEditMasterContext.Provider value={orderData.master}>
          <FormProvider {...formMethod}>
            <Container maxWidth={'md'}>
              <Box sx={{ width: '100%' }}>
                {/* フォーム */}
                <OtherOrderEdit dialogFlg={true} />

                {/* フッターボタン */}
                {props.showStatus !== 'Show' && (
                  <Box sx={{ textAlign: 'center' }}>
                    <Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                      {props.showStatus === 'Cancel'
                        ? '承認を取り消しますか？'
                        : '承認を行いますか？'}
                    </Typography>
                    <FooterButtonsContainer>
                      <CloseButton onClick={props.onClose} />
                      <Button onClick={() => approvalAction()}>OK</Button>
                    </FooterButtonsContainer>
                  </Box>
                )}
              </Box>
            </Container>
          </FormProvider>
        </OtherOrderEditMasterContext.Provider>
      </PageContext.Provider>
    );
  };

  /** 画面タイトル */
  const title = `その他受注詳細`;

  return (
    <>
      {!isOpenWindow && (
        <>
          <Dialog
            open={props.open}
            title={title}
            onClose={onClose}
            messageApi={messageApi}
            maxWidth={'lg'}
          >
            {otherBody()};
          </Dialog>
        </>
      )}
      {props.open && isOpenWindow && (
        <>
          <NewWindow features={{ height: 720, width: 1280 }} onUnload={props.onClose}>
            <Box sx={{ m: 1 }}>
              <MessageListener messageApi={messageApi} />
            </Box>
            <Heading>{title}</Heading>
            <Box m={4} />
            {otherBody()};
          </NewWindow>
        </>
      )}
    </>
  );
};

export default OtherOrderEditDialog;
