import React from 'react';

import { Box } from '@mui/material';

import { BodyLabel } from 'components/parts/atoms';

/** 製版区分の無償は含めない */
export const FreeNotice: React.VFC = () => {
  return (
    <Box>
      {
        <BodyLabel
          sx={{
            padding: 2,
            textAlign: 'right',
          }}
        >
          ※運賃の版数には、無償・合版の製版区分を含めません
        </BodyLabel>
      }
    </Box>
  );
};
