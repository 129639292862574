import saveAs from 'file-saver';
import useApiClient from 'hooks/useApiClient';
import { useState } from 'react';

import { ResponseMessage } from 'api/types/common/apiResponse';

import { PrintTarget } from '../types';

export const usePrint = (
  messageHandler: (messages: ResponseMessage[]) => void,
): {
  printProcess: (condition: PrintTarget) => void;
  printByCustomerProcess: (condition: PrintTarget) => void;
  loading: boolean;
} => {
  const { apiClient } = useApiClient(messageHandler);
  const [loading, setLoading] = useState(false);

  // 印刷処理
  const printProcess = (condition: PrintTarget): void => {
    (async () => {
      setLoading(true);
      await apiClient.axios
        .post(`/api/v1/DeliveryPrint/PrintProcess`, condition)
        .then((response) => {
          const fileData = response.data;
          if (fileData && typeof fileData === 'string') {
            // PDFのBase64データをBlob後に別ウィンドウにて表示
            const blob = new Blob([Buffer.from(fileData, 'base64')], {
              type: 'application/pdf',
            });

            const fileURL = URL.createObjectURL(blob);
            window.open(fileURL);
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    })();
  };

  // 得意先別印刷処理
  const printByCustomerProcess = (condition: PrintTarget): void => {
    (async () => {
      setLoading(true);
      try {
        const response = await apiClient.axios.post(
          `/api/v1/DeliveryPrint/PrintProcessByCustomer`,
          condition,
          {
            responseType: 'arraybuffer', // バイナリデータとして受け取る
          },
        );
        console.log(response);

        // 日付を取得して、ファイル名に日付を含める
        const currentDate = new Date().toISOString().split('T')[0].replace(/-/g, '.'); // "2024.09.24" のようにフォーマット
        const filename = `${currentDate}.zip`;
        console.log(filename);

        // ZIPファイルとしてダウンロード
        const blob = new Blob([response.data], { type: 'application/zip' });
        saveAs(blob, filename);
      } catch (e) {
        console.error('エラーが発生しました:', e);
        // エラー処理（ユーザーへの通知など）
      } finally {
        setLoading(false);
      }
    })();
  };

  return {
    printProcess,
    printByCustomerProcess,
    loading,
  };
};

export default usePrint;
