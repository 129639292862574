import { PlateMakingCode } from 'constants/define';

import { useCylinderOrderEditMaster } from './useCylinderOrderEditMaster';

/** 製版_ID取得Hooks */
export const usePlateMakingId = (): {
  fullId: string;
  kaihanId: string;
  zouhanId: string;
  hanberiId: string;
  reChromeId: string;
  awaseId: string;
  chousaId: string;
  freeReChromeId: string;
  freeTestId: string;
  freeFullKaihanId: string;
  freeHanberiId: string;
  findByCode: (plateMakingCode: PlateMakingCode) => string;
} => {
  const {
    master: { plateMakingList },
  } = useCylinderOrderEditMaster();

  const findByCode = (plateMakingCode: PlateMakingCode) => {
    return plateMakingList.find((x) => x.decisionCode === plateMakingCode)?.value ?? '';
  };

  // 製版区分IDを取得
  const fullId = findByCode(PlateMakingCode.FullDiv);
  const kaihanId = findByCode(PlateMakingCode.KaihanDiv);
  const zouhanId = findByCode(PlateMakingCode.ZouhanDiv);
  const hanberiId = findByCode(PlateMakingCode.HanberiDiv);
  const reChromeId = findByCode(PlateMakingCode.ReChromeDiv);
  const awaseId = findByCode(PlateMakingCode.AwaseDiv);
  const chousaId = findByCode(PlateMakingCode.ChousaDiv);

  // 無償の製版区分IDを取得
  const freeReChromeId = findByCode(PlateMakingCode.FreeReChromeDiv);
  const freeTestId = findByCode(PlateMakingCode.FreeTestDiv);
  const freeFullKaihanId = findByCode(PlateMakingCode.FreeFullKaihanDiv);
  const freeHanberiId = findByCode(PlateMakingCode.FreeHanberiDiv);

  return {
    fullId,
    kaihanId,
    zouhanId,
    hanberiId,
    reChromeId,
    awaseId,
    chousaId,
    freeReChromeId,
    freeTestId,
    freeFullKaihanId,
    freeHanberiId,
    findByCode,
  };
};
