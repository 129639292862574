import { useAuth } from 'hooks/useAuth';
import React from 'react';

import { Box } from '@mui/material';

import { usePageContext } from '../../hooks/usePageContext';
import { PageState } from '../../types';
import Approval from './Approval';
import HanberiPrice from './HanberiPrice';
import NormalPrice from './NormalPrice';
import { Notice } from './Notice';
import OtherCylinderPrice from './OtherCylinderPrice';
import { FreeNotice } from './OtherCylinderPrice/FreeNotice';
import OtherImagePrice from './OtherImagePrice';
import PriceHeader from './PriceHeader';
import ReChromiumPrice from './ReChromiumPrice';
import Remark from './Remark';
import SetPrice from './SetPrice';
import TotalPrice from './TotalPrice';
import UnitPrice from './UnitPrice';
import Url from './Url';

/** シリンダー受注 製版価格ンポーネント */
const Price: React.VFC = () => {
  const { pageState } = usePageContext();
  // セッション情報
  const { session } = useAuth();
  if (!session) {
    throw new Error();
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <PriceHeader />
      </Box>

      <Box mt={6} sx={{ width: '105%', display: 'flex' }}>
        <Box sx={{ width: '50%' }}>
          <UnitPrice />
          <Box my={6} />
          <NormalPrice />
          <Box my={6} />
          <OtherCylinderPrice />
          <Box>{pageState === PageState.Edit && <FreeNotice />}</Box>
          <Box my={6} />
          <OtherImagePrice />
        </Box>
        <Box mx={2} />
        <Box
          sx={{
            width: '50%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <ReChromiumPrice />
            <Box my={6} />
            <HanberiPrice />
            <Box my={6} />
            <SetPrice />
            <Box my={6} />
          </Box>
          <Box>
            <TotalPrice />
          </Box>
          <Box my={2} />
          <Box>
            <Approval />
          </Box>
        </Box>
      </Box>
      <Box my={1} />
      <Box sx={{ width: '100%', display: 'flex' }}>
        <Box sx={{ width: '50%' }}></Box>
        <Box mx={2} />
        <Box sx={{ width: '50%' }}>{pageState === PageState.Edit && <Notice />}</Box>
      </Box>
      <Box my={2} />
      <Box sx={{ width: '100%', display: 'flex' }}>
        <Box sx={{ width: '50%' }}>
          <Remark />
        </Box>
      </Box>
      <Box my={2} />
      <Box sx={{ width: '100%', display: 'flex' }}>
        <Box sx={{ width: '50%' }}>
          <Url />
        </Box>
      </Box>
    </>
  );
};

export default Price;
